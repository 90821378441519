import * as React from 'react';
import { Link } from 'gatsby';

import { Icon, Header, Segment, Grid } from 'semantic-ui-react';

import { colors } from './sidebar/utils/tokens';

// Array.flat() does not work with older browsers.
function flat(input, depth = 1, stack = []) {
  for (let item of input) {
    if (item instanceof Array && depth > 0) {
      flat(item, depth - 1, stack);
    } else {
      stack.push(item);
    }
  }

  return stack;
}

function getFlattenedItemList(item) {
  let all_items = [];

  if (item.link) {
    all_items.push({ link: item.link, title: item.title });
  }

  if (item.items) {
    for (let i of item.items) {
      all_items.push(getFlattenedItemList(i));
    }
  }

  return flat(all_items);
}

export const Button: React.FunctionComponent<{
  title: string;
  link: string;
  is_next: boolean;
}> = ({ title, link, is_next }) => {
  return (
    <Link to={link}>
      <Segment
        css={{
          ':hover': { borderColor: colors.mondaic.orange },
          display: `flex`,
          justifyContent: is_next ? 'flex-end' : 'flex-start',
          alignItems: `center`,
          color: colors.mondaic.orange,
        }}
      >
        <div style={{ order: 1, paddingLeft: `1em`, paddingRight: `1em` }}>
          <Header as="h5" style={{ opacity: 0.5, textTransform: `uppercase` }}>
            {is_next ? `Next` : `Previous`}
          </Header>
          {title}
        </div>
        <Icon
          style={{ order: is_next ? 2 : 0 }}
          name={is_next ? `arrow right` : `arrow left`}
          size="big"
        />
      </Segment>
    </Link>
  );
};

export const PreviousNextButtons: React.FunctionComponent<{
  pathname: string;
  itemListDocs: any;
  currentVersion: string;
}> = ({ pathname, itemListDocs, currentVersion }) => {
  let index = -1;

  // Flattened item list to ease the rest - just find the index of the current
  // path and previous and next links are easy to create. Can be more
  // sophisticated at one point but this should do the trick.
  const flatList = getFlattenedItemList(itemListDocs);
  const splitPath = pathname.split('/');

  let thisPathname = pathname;

  // If the second item is not a version number, inject the current one so the
  // buttons also work when no version number is specified.
  if (splitPath.length >= 2 && !/^\d{1,2}\.\d{1,3}\..+$/.exec(splitPath[1])) {
    thisPathname =
      // splitPath.slice(0, 1).join('/') +
      `/${currentVersion}/` +
      splitPath.slice(2).join('/');
  }

  for (let [i, value] of flatList.entries()) {
    if (value.link === thisPathname) {
      index = i;
      break;
    }
  }

  let previous = index > 0 ? flatList[index - 1] : null;
  let next = index < flatList.length - 1 ? flatList[index + 1] : null;

  if (index === 0) {
    previous = { title: 'Documentation Start', link: '/' };
  }

  return (
    <Grid stackable columns={2} style={{ marginTop: `2em` }}>
      <Grid.Column>
        {previous && (
          <Button title={previous.title} link={previous.link} is_next={false} />
        )}
      </Grid.Column>
      <Grid.Column>
        {next && <Button title={next.title} link={next.link} is_next={true} />}
      </Grid.Column>
    </Grid>
  );

  return <div>{pathname}</div>;
};
